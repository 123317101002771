<script lang="ts">
  import Button from '$lib/components/button/button.svelte';
  import Text from '$lib/components/text/text.svelte';
  import type { MenuLinkGroup } from '$lib/contentful/models/menu-link-group';

  export let linkGroup: MenuLinkGroup;
  const { title, links, callsToAction, entityId } = linkGroup;
</script>

<div class="footer-links">
  <Text
    class="!font-bold text-gradient-ultraviolet"
    variant="body-24"
    {entityId}
    fieldId="title">{title}</Text
  >
  {#each links as { linkText, link }}
    <a
      href={link}
      target={link.startsWith('/') ? '_self' : '_blank'}
      class="footer-link">{linkText}</a
    >
  {/each}
  <div class="flex flex-col gap-4 pt-8 max-lg:hidden">
    {#each callsToAction as { text, ...rest }}
      <Button {...rest}>{text}</Button>
    {/each}
  </div>
</div>

<style lang="postcss">
  .footer-links {
    @apply flex flex-col gap-1 whitespace-nowrap;
  }

  .footer-link {
    @apply text-white font-sans text-base font-normal hover:text-blue-gray-100 transition-colors;
  }
</style>
