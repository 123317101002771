<script lang="ts">
  import type { MarketoForm as IMarketoForm } from '$lib/contentful/models/marketo-form';
  import type { MenuLinkGroup } from '$lib/contentful/models/menu-link-group';
  import Box from '$lib/layout/grid/box.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import MarketoForm from '$lib/components/marketo-form/marketo-form.svelte';
  import SocialLinks from './social-links.svelte';
  import StatusBadge from './status-badge.svelte';
  import Links from './links.svelte';
  import Legal from './legal.svelte';

  export let links: MenuLinkGroup[];
  export let form: IMarketoForm | undefined;
</script>

<footer class="footer">
  <div class="footer-content">
    <Grid as="div" layout="columns">
      <Box
        as="div"
        offset={4}
        span={8}
        class="flex lg:flex-row lg:items-center lg:justify-between max-lg:flex-col max-lg:gap-6"
      >
        <StatusBadge />
        <SocialLinks />
        <hr class="lg:hidden text-blue-gray-900" />
      </Box>
    </Grid>
    <Grid as="div" layout="columns">
      <Box
        as="div"
        offset={4}
        span={8}
        class="flex flex-wrap justify-between lg:flex-row max-lg:flex-col gap-6"
      >
        {#each links.slice(0, 3) as linkGroup}
          <Links {linkGroup} />
        {/each}
        <div class="flex flex-col gap-12">
          {#each links.slice(3) as linkGroup}
            <Links {linkGroup} />
          {/each}
        </div>
      </Box>
    </Grid>
    <Grid as="div" layout="columns">
      <Box as="div" offset={4} span={4} class="flex flex-col gap-4">
        {#if form}
          <MarketoForm content={form} />
        {/if}
        <Legal />
      </Box>
    </Grid>
  </div>
</footer>

<style lang="postcss">
  .footer {
    @apply flex flex-col bg-background;

    background-image: url('/images/backgrounds/temporal-moonscape-footer.svg'),
      url('/images/backgrounds/stars.png');

    @supports (background-image: url('/images/backgrounds/stars.avif')) {
      background-image: url('/images/backgrounds/temporal-moonscape-footer.svg'),
        url('/images/backgrounds/stars.avif');
    }

    background-size: 100% auto;
    background-position: bottom, center;
    background-repeat: no-repeat, repeat;
  }

  .footer-content {
    @apply flex flex-col max-lg:gap-6 lg:gap-y-24 max-lg:pt-4 lg:pt-32 max-md:pb-[16rem] md:pb-[24rem] lg:pb-[32rem] xl:pb-[40rem] 2xl:pb-[48rem] 3xl:pb-[56rem] 4xl:pb-[64rem] w-full h-full;
  }
</style>
