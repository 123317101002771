<script lang="ts">
  import { onMount } from 'svelte';
  import { hiddenBannerText } from '$lib/stores/announcement-banner';
  import Button from '../button/button.svelte';
  import type { Banner } from '$lib/contentful/models/banner';

  export let banner: Banner | undefined;
  const preLinkText = banner?.preLinkText ?? '';
  const linkToText = banner?.linkToText ?? '';
  const postLinkText = banner?.postLinkText ?? '';
  const link = banner?.link ?? '';
  let open = false;

  onMount(() => {
    const bannerHasChanged = $hiddenBannerText !== preLinkText;

    if ($hiddenBannerText && bannerHasChanged) {
      hiddenBannerText.set(null);
      open = true;
    } else {
      open = !$hiddenBannerText;
    }
  });

  const closeBanner = () => {
    hiddenBannerText.set(preLinkText);
    open = false;
  };
</script>

{#if open && banner}
  <div class="z-10 bg-ultraviolet bg-center px-10 py-1 text-lg text-white">
    <div class="flex items-center justify-between">
      <div
        class="max-w-xs grow text-left sm:max-w-sm md:max-w-full md:text-center"
      >
        <p>
          {preLinkText}
          <a href={link} class="pulsar-link">
            {linkToText}
          </a>
          {postLinkText}
        </p>
      </div>
      <Button variant="ghost" leadingIcon="close" on:click={closeBanner} />
    </div>
  </div>
{/if}
