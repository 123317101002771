<script lang="ts">
  import AnnouncementBanner from '$lib/components/announcement-banner/announcement-banner.svelte';
  import Footer from '$lib/components/footer/footer.svelte';
  import SiteNavigation from '$lib/components/site-navigation/site-navigation.svelte';
  import type { PageData } from './$types';

  export let data: PageData;

  const {
    banner,
    globalSettings: { footerLinks, footerForm, mainMenu, menuCtas },
  } = data;
</script>

<div class="overflow-x-clip">
  <AnnouncementBanner {banner} />
  <SiteNavigation links={mainMenu} callsToAction={menuCtas} />
  <slot />
  <Footer form={footerForm} links={footerLinks} />
</div>
