<script lang="ts">
  import Icon from '$lib/components/icon/icon.svelte';
</script>

<div class="social-links">
  <a
    class="social-link"
    target="_blank"
    href="https://www.youtube.com/temporalio"
    aria-label="Follow us on YouTube"
  >
    <Icon name="youtube" />
  </a>
  <a
    class="social-link"
    target="_blank"
    href="https://twitter.com/temporalio"
    aria-label="Follow us on Twitter"
  >
    <Icon name="twitter" />
  </a>
  <a
    class="social-link"
    target="_blank"
    href="https://github.com/temporalio"
    aria-label="Follow us on GitHub"
  >
    <Icon name="github" />
  </a>
  <a
    class="social-link"
    target="_blank"
    href="https://www.linkedin.com/company/temporal-technologies"
    aria-label="Follow us on Linkedin"
  >
    <Icon name="linkedin" />
  </a>
  <a
    class="social-link"
    target="_blank"
    href="https://t.mp/slack"
    aria-label="Join us on Slack"
  >
    <Icon name="slack" />
  </a>
</div>

<style lang="postcss">
  .social-links {
    @apply flex flex-row text-white gap-4 max-lg:justify-between max-lg:px-6;
  }

  .social-link {
    @apply hover:text-indigo-100 transition-colors;
  }
</style>
